import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

function Project(proj) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  const project = proj.proj;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(ref.current);
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      let [target] = [...ref.current.children];
      target.style.animationPlayState = "running";
    }
  }, [isIntersecting]);

  return (
    <>
      <div className="project-wrapper" id={project.id} ref={ref}>
        <div className={project.id % 2 === 0 ? "project" : "project reverse"}>
          <div className="project-img-container">
            <img
              src={project.mainImg}
              alt="Project demo"
              className="project-img"
            />
          </div>
          <div className="project-info">
            <div className="project-heading">
              <h4 className="project-title">{project.title}</h4>
              <p className="project-date">{project.date}</p>
            </div>
            <p className="project-about">{project.description}</p>
            <div className="project-details">
              <div className="project-skills">
                {project.skills.map((skill) => {
                  return <span className="project-skill">{skill}</span>;
                })}
              </div>
              <div className="project-links">
                <Link
                  to={project.gitLink}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Github repository link"
                  className={project.gitLink === "#" ? "disabled" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="#000000"
                    viewBox="0 0 256 256"
                    className="project-link"
                  >
                    <path d="M208.31,75.68A59.78,59.78,0,0,0,202.93,28,8,8,0,0,0,196,24a59.75,59.75,0,0,0-48,24H124A59.75,59.75,0,0,0,76,24a8,8,0,0,0-6.93,4,59.78,59.78,0,0,0-5.38,47.68A58.14,58.14,0,0,0,56,104v8a56.06,56.06,0,0,0,48.44,55.47A39.8,39.8,0,0,0,96,192v8H72a24,24,0,0,1-24-24A40,40,0,0,0,8,136a8,8,0,0,0,0,16,24,24,0,0,1,24,24,40,40,0,0,0,40,40H96v16a8,8,0,0,0,16,0V192a24,24,0,0,1,48,0v40a8,8,0,0,0,16,0V192a39.8,39.8,0,0,0-8.44-24.53A56.06,56.06,0,0,0,216,112v-8A58.14,58.14,0,0,0,208.31,75.68ZM200,112a40,40,0,0,1-40,40H112a40,40,0,0,1-40-40v-8a41.74,41.74,0,0,1,6.9-22.48A8,8,0,0,0,80,73.83a43.81,43.81,0,0,1,.79-33.58,43.88,43.88,0,0,1,32.32,20.06A8,8,0,0,0,119.82,64h32.35a8,8,0,0,0,6.74-3.69,43.87,43.87,0,0,1,32.32-20.06A43.81,43.81,0,0,1,192,73.83a8.09,8.09,0,0,0,1,7.65A41.72,41.72,0,0,1,200,104Z"></path>
                  </svg>
                </Link>
                <Link
                  to={project.demoLink}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Live demo link"
                  className={project.demoLink === "#" ? "disabled" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="project-link external"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
