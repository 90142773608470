import Project from "./Project";

import projData from "../data/projectData";

function Projects() {
  return (
    <>
      <div className="projects-wrapper" id="projects">
        <div className="projects-container container">
          <h2 className="projects-title">Projects</h2>
          <div className="projects-section">
            {projData.map((proj) => {
              return <Project proj={proj} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
