import "./dist/styles.css";

import Home from "./pages/home";
import Nav from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Nav />
      <Home />
      <Footer />
    </>
  );
}

export default App;
