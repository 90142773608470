import { useState, useRef, useEffect } from "react";

import headshot from "../images/angled-noteeth-buttoned-touched-up -cropped.jpg";

function About() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(ref.current);
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      let [target] = [...ref.current.children];
      target.style.animationPlayState = "running";
    }
  }, [isIntersecting]);

  return (
    <>
      <div className="about-wrapper" id="about" ref={ref}>
        <div className="about-container container">
          <div className="about-img-container">
            <img src={headshot} alt="Me" className="about-img lazy loading" />
          </div>
          <div className="about-info">
            <div className="about-text">
              <h2 className="about-title">About Me</h2>
              <p className="about-blurb">
                Hey, my name is Adam Clifford, and I am a Full-Stack Developer.
                I'm always pursuing the meeting point of accessibility and
                creativity to make fluid, engaging, and robust user experiences.
              </p>
              <p className="about-blurb">
                When I'm not at my computer, I enjoy rock climbing, exploring
                the outdoors, pursuing pet projects, and spending time with my
                fiancée.
              </p>
            </div>
            <div className="tech-stack">
              <h3 className="tech-stack-title">Tech Stack</h3>
              <div className="tech-stack-inner-container">
                <section className="stack-item">
                  <img src="https://skillicons.dev/icons?i=js" alt="js logo" />
                </section>
                <section className="stack-item">
                  <img src="https://skillicons.dev/icons?i=ts" alt="ts logo" />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=html"
                    alt="html logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=css"
                    alt="css logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=react"
                    alt="react logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=nodejs"
                    alt="nodejs logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=sass"
                    alt="sass logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=tailwind"
                    alt="tailwind logo"
                  />
                </section>
                <section className="stack-item">
                  <img
                    src="https://skillicons.dev/icons?i=github"
                    alt="github logo"
                  />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
