// import mainImgEcomm from "../images/projects/adventura/adventura_home.png";
// import altImgEcomm from "../images/projects/adventura/adventura_home_bottom.png";
// import mainImgPoke from "../images/projects/pokedex/pokedex_home.png";
// import altImgPoke from "../images/projects/pokedex/pokedex_home_alt.png";
// import altImgPoke1 from "../images/projects/pokedex/pokedex_move_screen.png";
import mainImgPort from "../images/projects/portfolio/portfolio_home.png";
import altImgPort from "../images/projects/portfolio/portfolio_about.png";
import mainImgKilntrace from "../images/projects/kilnTrace/dashboard.png";
import altImgKilntrace from "../images/projects/kilnTrace/dialog.png";

const projects = [
  {
    id: 1,
    title: "Kiln Trace",
    date: "April 2024",
    description:
      "Kiln Trace is a CRUD application that is used to manage the state of pottery pieces in an art studio along with extensive information regarding individual pieces.(Temporarily Greyscale)",
    mainImg: mainImgKilntrace,
    altImgs: [altImgKilntrace],
    skills: [
      "TS",
      "React.js",
      "Tailwind",
      "CSS",
      "Node.js",
      "Express.js",
      "MongoDB",
      "Mongoose",
    ],
    gitLink: "https://github.com/AdamCliff/Kiln-Trace",
    demoLink: "#",
  },
  // {
  //   id: 2,
  //   title: "Portfolio",
  //   date: "January 2024",
  //   description:
  //     "A lightweight portfolio app used to showcase my technical and web design skills.",
  //   mainImg: mainImgPort,
  //   altImgs: [altImgPort],
  //   skills: ["HTML", "CSS", "JS", "React", "SCSS"],
  //   gitLink: "https://github.com/AdamCliff/portfolio",
  //   demoLink: "adamcliff.dev",
  // },
  // {
  //   id: 2,
  //   title: "Adventura",
  //   date: "January 2024",
  //   description:
  //     "An ecommerce app centered around outdoors apparel and equipment. Has a landing page with product and company info displayed in a various formats. Will have a cart and checkout system in the furutre, perhaps hooked up to stripe or another api.",
  //   mainImg: mainImgEcomm,
  //   altImgs: [altImgEcomm],
  //   skills: ["HTML", "CSS", "JS", "React"],
  //   link: "https://github.com/AdamCliff/ecomm",
  // },
  // {
  //   id: 3,
  //   title: "Pokedex",
  //   date: "December 2023",
  //   description:
  //     "A pokedex application that allows for the search or any pokemon within the pokeAPI database. Displays statistics for a given pokemon and provides a list of all moves that they can learn. The list pane allows for a move to be selected, and an in depth view for the move statistics is shown.",
  //   mainImg: mainImgPoke,
  //   altImgs: [altImgPoke, altImgPoke1],
  //   skills: ["HTML", "CSS", "JS"],
  //   link: "https://github.com/AdamCliff/Pokedex",
  // },
];

export default projects;
