import Hero from "../components/Hero";
import About from "../components/About";
import Projects from "../components/Projects";
import Contact from "../components/Contact";

function Home() {
  return (
    <>
      <div className="home">
        <Hero />
        <About />
        <Projects />
        <Contact />
      </div>
    </>
  );
}

export default Home;
