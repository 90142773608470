import { useState, useEffect, useRef } from "react";

function Contact() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(ref.current);
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      let target = ref.current;
      target.style.animationPlayState = "running";
    }
  }, [isIntersecting]);

  return (
    <>
      <div className="contact-wrapper" id="contact">
        <div className="contact-container container" ref={ref}>
          <div className="contact-contents">
            <h2 className="form-title">Contact</h2>
            <p className="contact-blurb">
              Feel free to reach out, and I'll get back to you as soon as I can!
            </p>
            <a
              href="mailto:clifford.adam.n@gmail.com"
              className="btn"
              target="_blank"
              rel="noreferrer"
              aria-label="'Email to' link"
            >
              Get In Touch
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
