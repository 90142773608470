import resume from "../images/other/Adam-Clifford-Resume.pdf";

function Navbar() {
  function OpenMobileNav() {
    const mobileNav = document.querySelector(".mobile-nav");
    mobileNav.style.left = "0";
    document.body.style.overflowY = "hidden";
  }

  function CloseMobileNav() {
    const mobileNav = document.querySelector(".mobile-nav");
    mobileNav.style.left = "-100%";
    document.body.style.overflowY = "visible";
  }

  return (
    <>
      <nav className="navbar-container">
        <a href="#home" className="navbar-link dev" aria-label="Hero link">
          Adam.dev
        </a>
        <div className="navbar-link-section">
          <button
            className="navbar-link"
            id="menu"
            onClick={OpenMobileNav}
            aria-label="Open mobile navigation"
          ></button>
          <div className="navbar-links">
            <a href="#about" className="navbar-link" aria-label="About link">
              About
            </a>
            <a
              href="#projects"
              className="navbar-link"
              aria-label="Projects link"
            >
              Projects
            </a>
            <a
              href="#contact"
              className="navbar-link"
              aria-label="Contact link"
            >
              Contact
            </a>
          </div>
        </div>
        <a
          href={resume}
          target="_blank"
          rel="noreferrer"
          className="navbar-link resume btn"
          aria-label="Resume PDF link"
        >
          Resume
        </a>
      </nav>
      <nav className="mobile-nav">
        <div className="nav-header">
          <a
            href="#home"
            className="navbar-link dev"
            onClick={CloseMobileNav}
            aria-label="Hero link"
          >
            Adam.dev
          </a>
          <button
            className="nav-close"
            onClick={CloseMobileNav}
            aria-label="Close mobile navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="nav-close-icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="navbar-link-section">
          <div className="navbar-links">
            <a
              href="#about"
              className="navbar-link"
              onClick={CloseMobileNav}
              aria-label="About link"
            >
              About
            </a>
            <a
              href="#projects"
              className="navbar-link"
              onClick={CloseMobileNav}
              aria-label="Projects link"
            >
              Projects
            </a>
            <a
              href="#contact"
              className="navbar-link"
              onClick={CloseMobileNav}
              aria-label="Contact link"
            >
              Contact
            </a>
          </div>
          <a
            href={resume}
            target="_blank"
            rel="noreferrer"
            className="navbar-link resume btn"
            aria-label="Resume PDF link"
          >
            Resume
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
